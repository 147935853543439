import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';

enum UserInitializedAction {
    SET_CHOOSE_OF_MODAL = 'setChoosingAfterModal'
}

export const userInitializedAction = createActionGroup({
    source: StoreFeatureNames.ACCOUNT_DATA_INITIALIZED,
    events: {
        [UserInitializedAction.SET_CHOOSE_OF_MODAL]: props<{ choose: boolean }>()
    }
});
